import { ClientService } from "@/api/instance";
import { DeAuthSession } from "@/api/de-auth";

export const GetProfile = async () => {
  try {
    let { data } = await ClientService().get("/profile");
    return data;
  } catch (e) {
    DeAuthSession(e);
    throw e;
  }
};

export const DeleteProfile = async () => {
  try {
    await ClientService().delete(`/profile`);
    localStorage.removeItem("token");
  } catch (e) {
    DeAuthSession(e);
    throw e;
  }
};

export const UpdateProfile = async user => {
  try {
    await ClientService().put(`/profile`, { user });
  } catch (e) {
    DeAuthSession(e);
    throw e;
  }
};

export const SetMFASetting = async (MFAStatus, preference) => {
  try {
    await ClientService().put("/profile/mfa", {
      MFAEnabled: MFAStatus,
      preference
    });
  } catch (e) {
    DeAuthSession(e);
    throw e;
  }
};

export const VerifyMFASetting = async code => {
  try {
    await ClientService().put("/profile/verify-mfa", { code });
  } catch (e) {
    DeAuthSession(e);
    throw e;
  }
};
