<template>
  <div class="profile-container">
    <h1 class="profile-title">Profile</h1>

    <v-container>
      <v-form ref="form">
        <p class="input-label">
          Username
          <span>*</span>
        </p>
        <v-text-field
          name="username"
          v-model="user.client.username"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
        <div class="split-input-container">
          <div>
            <p class="input-label">
              First Name
              <span>*</span>
            </p>
            <v-text-field
              name="first_name"
              v-model="user.client.first_name"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </div>
          <div>
            <p class="input-label">
              Last Name
              <span>*</span>
            </p>
            <v-text-field
              name="last_name"
              v-model="user.client.last_name"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </div>
        </div>
        <p class="input-label">
          Email
          <span>*</span>
        </p>
        <v-text-field
          name="email"
          v-model="user.email"
          outlined
          :rules="[rules.required, rules.email]"
        ></v-text-field>
        <div class="split-input-container">
          <div>
            <p class="input-label">
              Postcode
              <span>*</span>
            </p>
            <v-text-field
              name="postcode"
              v-model="user.client.postcode"
              outlined
              :rules="[rules.required, rules.postcode]"
            ></v-text-field>
          </div>
          <div>
            <p class="input-label">
              Contact Telephone Number
              <span>*</span>
            </p>
            <v-text-field
              name="tel"
              v-model="user.client.tel"
              type="tel"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </div>
        </div>
        <v-btn class="save-btn" color="primary" @click="updateProfile()"
          >Save</v-btn
        >
      </v-form>
    </v-container>

    <v-card v-show="display_2fa">
      <v-toolbar dark color="primary">
        <v-toolbar-title class="title"
          >Two Factor Authentication</v-toolbar-title
        >
      </v-toolbar>
      <v-container>
        <v-form ref="MFAform">
          <v-container style="display: flex">
            <v-switch
              v-model="mfa_enabled"
              class="auth_toggle"
              label="Enable Two-Factor Authentication?"
            ></v-switch>
            <v-card-actions>
              <v-btn icon @click="show_2fa_info = !show_2fa_info">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </v-card-actions>
          </v-container>
          <v-expand-transition>
            <div v-show="show_2fa_info">
              <v-divider></v-divider>
              <v-card-title>What is Two-Factor Authentication?</v-card-title>
              <v-card-text>
                Two Factor Authentication, or 2FA, is an extra layer of
                protection used to ensure the security of your account beyond
                just your username and password. If you would like to read more
                about it you can do so here:
              </v-card-text>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <v-btn
                  large
                  href="https://authy.com/what-is-2fa/"
                  style="margin-bottom: 16px"
                  >Authy - What Is 2FA?</v-btn
                >
              </div>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-show="mfa_enabled">
              <v-card-title>Authentication method:</v-card-title>
              <v-card-text>
                <v-radio-group v-model="autChoice">
                  <v-radio label="SMS" value="sms"></v-radio>
                  <v-radio label="Authy App" value="app"></v-radio>
                  <v-radio label="Email" value="email"></v-radio>
                  <v-radio label="Telephone" value="tel"></v-radio>
                </v-radio-group>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-form>
        <v-divider v-show="display_2fa"></v-divider>
        <v-btn color="secondary" class="save-btn" @click.stop="updateMFA"
          >Save</v-btn
        >
        <v-dialog v-model="show_mfa" width="500">
          <MFATokenEntry @codesubmission="verifyMFASetting"></MFATokenEntry>
        </v-dialog>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { MFA_ENABLED } from "@/config";
import EventBus from "@/event-bus.js";
import {
  GetProfile,
  UpdateProfile,
  SetMFASetting,
  VerifyMFASetting
} from "@/api/profile";
import MFATokenEntry from "@/components/mfa-token-entry.vue";
export default {
  components: { MFATokenEntry },
  data() {
    return {
      user: {},
      show_2fa_info: false,
      mfa_enabled: false,
      display_2fa: MFA_ENABLED,
      authChoice: null,
      show_mfa: false,
      active: 0,
      deleteDialog: false,
      deleteLoading: false,
      rules: {
        required: value => !!value || "Required",
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(value) || "Invalid email";
        },
        postcode: value => {
          const pattern = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g;
          return pattern.test(value) || "Invalid postcode ";
        }
      }
    };
  },
  async beforeMount() {
    let { user, mfa_enabled } = await GetProfile();
    this.user = user;
    this.mfa_enabled = mfa_enabled;
  },
  methods: {
    async updateProfile() {
      if (this.$refs.form.validate()) {
        try {
          await UpdateProfile(this.user);
          EventBus.$emit("toast", {
            text: "Profile Updated",
            type: "success"
          });
        } catch (err) {
          EventBus.$emit("toast", {
            text: "Could not update profile",
            type: "error"
          });
          throw err;
        }
      }
    },
    async updateMFA() {
      if (this.$refs.MFAform.validate()) {
        await SetMFASetting(this.mfa_enabled, this.authChoice);
        this.show_mfa = true;
      }
    },
    async verifyMFASetting(code) {
      await VerifyMFASetting(code);
      this.show_mfa = false;
    }
  }
};
</script>

<style scoped>
.profile-container {
  max-width: 650px;
  margin: 0 auto;
}
.profile-title {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-align: center;
  color: var(--v-grey-base);
}
::v-deep .v-stepper__label {
  text-align: center;
  display: block !important;
}
::v-deep .v-stepper__step__step {
  height: 48px;
  width: 48px;
  font-size: 1.5em;
}
::v-deep .v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 48px -56px 0;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
::v-deep .v-stepper__step__step .v-icon.v-icon {
  font-size: 1.7rem;
}
.input-label {
  font-size: 20px;
  letter-spacing: normal;
  margin-top: 10px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
::v-deep .v-input__slot {
  background: #fff !important;
  border-radius: 10px;
}
.split-input-container {
  display: flex;
  justify-content: space-between;
}
.split-input-container > div {
  width: 49%;
}

.save-btn {
  margin-bottom: 30px;
  float: right;
}
::v-deep .v-btn__content {
  font-size: 18px;
}

@media only screen and (max-width: 1281px) {
  .profile-title {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  ::v-deep .v-stepper__label {
    display: none !important;
  }
  ::v-deep .v-stepper__step--active .v-stepper__label {
    display: block !important;
  }
  ::v-deep .v-stepper__step__step {
    height: 24px;
    width: 24px;
    font-size: 0.75em;
  }
  ::v-deep .v-stepper__step {
    flex-basis: auto;
    max-width: 150px;
  }
  ::v-deep .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 35px -20px 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  ::v-deep .v-stepper__step__step .v-icon.v-icon {
    font-size: 1.2rem;
  }
  .name-container {
    flex-direction: column;
  }
  #first-name {
    width: 100%;
  }
  #last-name {
    width: 100%;
  }
}
</style>
